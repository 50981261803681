/* STYLES FOR HERO SECTION */
.partnership-hero-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 105vh;
  overflow: hidden;
  background-color: rgba(18, 9, 29, 1);
  background-image:
    linear-gradient(to bottom,
      rgba(18, 9, 29, 1) 0%,
      rgba(18, 9, 29, 0) 10%),
    url("../../assets/img/planet_2.png");
    background-size: cover;
    background-position: center 300px;
    background-repeat: no-repeat;
}

.partnership-hero-left {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
  gap: 20px;
  padding: 0 0 0 96px !important;
}

.partnership-hero-section h1 {
  text-align: left;
  font-size: 60px !important;
  font-weight: 700 !important;
  line-height: 112% !important;
  color: white !important;
}

.partnership-hero-section h6 {
  text-align: left;
  color: rgba(255, 255, 255, 0.72) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 115% !important;
}

.partnership-hero-section button {
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6257FF !important;
  border-radius: 16px !important;
  padding: 12px 20px !important;
  width: fit-content;
}

.partnership-hero-section img {
  width: 80%;
  height: auto;
  object-fit: cover;
  border-radius: 16px;
}

@media (max-width: 768px) {
  .partnership-hero-left {
    margin-top: 100px !important;
    padding-left: 48px !important;
    padding-right: 16px !important;
    align-items: center;
  }

  .partnership-hero-section h1 {
    font-size: 40px !important;
    line-height: 112% !important;
  }

  .partnership-hero-section h6 {
    text-align: center;
  }
}

/* STYLES FOR COMMISSION SECTION */
.commission-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px !important;
  padding: 100px 96px;
  background: rgba(235, 237, 244, 1);
}

.commission-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: linear-gradient(140.06deg, #463A55 0%, #12091D 84.43%);
  border-radius: 16px;
  padding: 60px 36px;
  text-align: center;
  align-items: center;
  margin: auto;
  color: white;
  width: 90%;
}

.wcommission-container h3 {
  font-weight: 700 !important;
  font-size: 36px !important;
  line-height: 130% !important;
  letter-spacing: 0px !important;
  text-align: center !important;
  max-width: 850px;
}

.commission-container h6 {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 130% !important;
  letter-spacing: 0px !important;
  text-align: center !important;
  max-width: 850px;
}

.slider-box {
  margin: 100px 0 0 0;
  width: 93% !important;
}

.commission-section h5 {
  font-weight: 400 !important;
  font-size: 32px !important;
  line-height: 130% !important;
  letter-spacing: 0px !important;
  text-align: center !important;
}

@media (max-width: 768px) {
  .commission-section {
    padding: 100px 16px;
  }

  .partnership-hero-section h1 {
    font-size: 40px !important;
    line-height: 112% !important;
  }

  .partnership-hero-section h6 {
    text-align: center;
  }
}

/* STYLES FOR STEPS SECTION */
.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #EBEDF4 0%, #FFFFFF 100%);
  padding: 100px 96px !important;
  gap: 40px !important;
}

.steps-container h3 {
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 130% !important;
  letter-spacing: 0 !important;
}

.steps-card {
  display: flex;
  justify-content: center;
}

.steps-card-content {
  display: flex;
  flex-direction: column;
  flex-direction: column !important;
  gap: 16px !important;
  background: white;
  border-radius: 16px !important;
  padding: 44px;
  text-align: left;
  width: 100%;
  box-shadow: 0px 3px 6px 0px #00000008,
    0px 12px 12px 0px #00000008,
    0px 26px 16px 0px #00000005,
    0px 46px 19px 0px #00000000,
    0px 72px 20px 0px #00000000 !important;
}

.steps-circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.steps-icon {
  height: 20px;
  width: 20px;
}

.steps-title {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 130% !important;
  letter-spacing: 0 !important;
}

.steps-description {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #6B737E;
  line-height: 130% !important;
  letter-spacing: 0 !important;
}

.steps-signup-link {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: rgba(98, 87, 255, 1);
  line-height: 130% !important;
  letter-spacing: 0 !important;
  text-decoration: none;
}

@media (max-width: 768px) {
  .steps-container {
    padding: 100px 16px !important;
  }
}

/* STYLES FOR PARTNERSHIPS */
.partnerships-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 97px 96px;
  background-color: rgba(18, 9, 29, 1);
  background-image:
    linear-gradient(to bottom,
      rgba(18, 9, 29, 1) 0%,
      rgba(18, 9, 29, 0) 50%),
    url("../../assets/img/planet_3.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  text-align: center;
  gap: 20px !important;
  min-height: 355px !important;
}

.partnerships-section h3 {
  color: white;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 130% !important;
  letter-spacing: 0 !important;
}

.partnerships-section h6 {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 130% !important;
  letter-spacing: 0 !important;
  max-width: 550px;
}

.partnerships-section button {
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6257FF !important;
  border-radius: 16px !important;
  padding: 12px 20px !important;
  width: fit-content;
  align-self: center;
  margin-top: 0 !important;
}

@media (max-width: 768px) {
  .partnerships-section {
    padding: 100px 16px !important;
  }
}

/* STYLES FOR PARTNER PROGRAM SECTION */
.partner-program-container {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #EBEDF4 0%, #FFFFFF 100%);
  padding: 100px 96px !important;
  gap: 40px !important;
}

.partner-program-container h3 {
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 130% !important;
  letter-spacing: 0 !important;
}

.partner-program-card {
  display: flex;
  justify-content: center;
}

.partner-program-card-content {
  display: flex;
  flex-direction: column;
  flex-direction: column !important;
  gap: 16px !important;
  background: white;
  border-radius: 16px !important;
  padding: 44px;
  text-align: left;
  width: 100%;
  box-shadow: 0px 3px 6px 0px #00000008,
    0px 12px 12px 0px #00000008,
    0px 26px 16px 0px #00000005,
    0px 46px 19px 0px #00000000,
    0px 72px 20px 0px #00000000 !important;
}

.partner-program-circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.partner-program-icon {
  height: 20px;
  width: 20px;
}

.partner-program-title {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 130% !important;
  letter-spacing: 0 !important;
}

.partner-program-description {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 130% !important;
  letter-spacing: 0 !important;
  color: #6B737E;
}

@media (max-width: 768px) {
  .partner-program-container {
    padding: 100px 16px !important;
  }
}