/* STYLES FOR HERO SECTION */
.main-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(18, 9, 29, 1);
  background-image:
    linear-gradient(to bottom,
      rgba(18, 9, 29, 1) 0%,
      rgba(18, 9, 29, 0) 10%),
    url("../../assets/img/planet_2.png");
    background-size: cover;
    background-position: center 300px;
  background-repeat: no-repeat;
}

.blur-container {
  /* position: absolute; */
  /* bottom: 0; */
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: none;
  border-radius: 32px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  box-shadow: 0px 2.95px 5.89px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 11.79px 11.79px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 25.54px 15.72px 0px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 45.18px 18.66px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 70.72px 19.65px 0px rgba(0, 0, 0, 0);
  backdrop-filter: blur(98.22547912597656px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* justify-content: flex-start;
  overflow: hidden; */
  width: 60vw;
  height: 55vh;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  /* position: relative; */
  width: 90%;
  max-width: 800px;
  /* top: 15%; */
  transform: none;
}

.middle-image-container {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.hero-img {
  position: relative;
  width: 100%;
}

.hero-img-overlay {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.image-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  width: 90%;
  height: auto;
  pointer-events: none;
}

.image-container img {
  pointer-events: none;
}

.main-image {
  width: 95%;
  height: auto;
  border-radius: 16px;
  pointer-events: none;
}

.chat-overlay {
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 4px;
  pointer-events: none;
}

.chat-row {
  display: flex;
  align-items: flex-start;
}

.message-column {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.message-column.right {
  align-items: flex-end;
}

.message-column.left {
  align-items: flex-start;
}

.chat-row.left {
  justify-content: flex-start;
}

.chat-row.right {
  flex-direction: row-reverse;
}

.chat-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.chat-message.right {
  align-items: flex-end;
}

.user-name {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: 300;
}

.chat-bubble {
  text-align: left;
  padding: 11px 13px;
  border-radius: 16px;
  word-break: break-word;
  box-shadow: 0px 3.57px 11.8px 0px rgba(97, 88, 250, 0.4);
}

.chat-bubble p {
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.chat-bubble.ai {
  background: rgba(97, 88, 250, 1);
  color: white;
  max-width: 320px;
}

.chat-bubble.user {
  background: rgba(245, 248, 253, 1);
  color: black;
  max-width: 320px;
}

.chat-avatar {
  width: 38px;
  height: 38px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 24px;
}

.chat-avatar img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.user-initial {
  width: 38px;
  height: 38px;
  background: rgba(98, 87, 255, 1);
  color: white;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 24px;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-section h1 {
  text-align: center;
  font-size: 60px !important;
  font-weight: 700 !important;
  line-height: 67px !important;
  color: white !important;
}

.main-section h6 {
  color: rgba(255, 255, 255, 0.72) !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
}

.free-button {
  color: white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  background: linear-gradient(177.95deg, #6257FF 2.52%, #5145D0 97.69%);
  border-radius: 16px !important;
  padding: 12px 20px !important;
  min-width: 160px !important;
}

.main-marketplace-button {
  color: white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  background-color: rgba(255, 255, 255, 0.12) !important;
  backdrop-filter: blur(100px);
  border-radius: 16px !important;
  padding: 12px 20px !important;
  width: 160px !important;
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .chat-overlay {
    top: 15% !important;
    width: 80%;
  }

  .middle-image-container {
    top: 48%;
    width: 80%;
  }

  .main-content {
    top: 10%;
    max-width: 900px;
    gap: 14px;
  }

  .main-section h1 {
    font-size: 44px !important;
    line-height: 52px !important;
  }

  .main-section h6 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .image-container {
    width: 100%;
    padding-bottom: 24px;
  }

  .main-image {
    border-radius: 20px;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .middle-image-container {
    top: 48%;
    width: 80%;
  }

  .chat-overlay {
    top: 0;
  }

  .main-content {
    top: 10%;
  }

  .main-section h1 {
    font-size: 38px !important;
    line-height: 52px !important;
  }

  .main-section h6 {
    font-size: 18px !important;
    line-height: 24px !important;
  }
}

@media (max-width: 768px) {
  .main-section {
    justify-content: center !important;
  }
  
  .mobile-main-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;
  }

  .mobile-blur {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 32px !important;
    box-shadow: 0px 2.95px 5.89px 0px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 11.79px 11.79px 0px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 25.54px 15.72px 0px rgba(0, 0, 0, 0.02);
    box-shadow: 0px 45.18px 18.66px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 70.72px 19.65px 0px rgba(0, 0, 0, 0);
    backdrop-filter: blur(98.22547912597656px);
  }

  .hero-img {
    margin-bottom: -10px;
  }

  .mobile-main-content h1 {
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .mobile-main-content h6 {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .main-section h1 {
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .main-section h6 {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .main-content {
    top: 10% !important;
    width: 90% !important;
    padding: 0 12px;
    gap: 8px;
  }

  .free-button,
  .main-marketplace-button {
    width: 100% !important;
    margin-top: 0 !important;
  }

  .stack-buttons {
    flex-direction: column !important;
    width: 100%;
  }

  .main-image {
    width: 100% !important;
  }

  .middle-image-container {
    width: 95% !important;
    top: 36% !important;
  }

  .image-container img {
    filter: none !important;
    transform: scale(1) !important;
    opacity: 1 !important;
  }

  .chat-overlay {
    display: none;
  }
}

@media (max-width: 480px) {
  .middle-image-container {
    top: 30% !important;
    width: 100%;
  }
}

/* STYLES FOR LEARN MORE */
.learn-more-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  padding: 100px 0;
}

.learn-more-grid-container {
  width: 70% !important;
}

.learn-more-left {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  gap: 20px !important;
}

.learn-more-left h3 {
  font-size: 36px !important;
  font-weight: 700 !important;
  letter-spacing: 1px !important;
  line-height: 46px !important;
}

.learn-more-left h6 {
  color: #6B737E;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
}

.learn-more-image-container {
  display: flex;
  justify-content: center;
}

.learn-more-image {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.reports-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 90px 96px !important;
  gap: 20px !important;
}

.reports-container h3 {
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 63px !important;
  letter-spacing: 1px !important;
}

.reports-container h6 {
  color: #6B737E;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
}

.reports-image {
  width: 100%;
  max-width: 912px;
  border: 10px solid #EBEDF4;
  border-radius: 16px;
}

.reports-container button {
  color: white !important;
  font-size: 16px !important;
  border-radius: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6257FF !important;
  padding: 12px 20px 12px 20px !important;
  width: fit-content;
}

@media (max-width: 768px) {
  .learn-more-container {
    padding: 100px 16px;
  }

  .learn-more-grid-container {
    width: 100% !important;
  }

  .learn-more-left button {
    width: 100%;
  }
}

/* STYLES FOR BUSINESS OUTCOMES */
.business-metrics {
  display: flex;
  flex-direction: column;
  padding: 97px 96px;
  background-color: rgba(18, 9, 29, 1);
  background-image:
    linear-gradient(to bottom,
      rgba(18, 9, 29, 1) 0%,
      rgba(18, 9, 29, 0) 10%),
    url("../../assets/img/planet_2.png");
  background-size: cover;
  background-position: center 110px;
  background-repeat: no-repeat;
  text-align: center;
  gap: 44px !important;
}

.business-metrics h3 {
  color: white;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 63px !important;
  letter-spacing: 1px !important;
}

.metrics-grid {
  margin-bottom: 60px;
}

.metric-item {
  text-align: center;
  position: relative;
  padding-right: 20px;
}

.metric-item:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.metric-value {
  font-size: 84px !important;
  font-weight: 700 !important;
  color: white;
}

.metric-label {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: white;
}

.partners-section {
  background-color: #ebedf4;
  padding: 100px 96px;
}

.partner-item {
  text-align: center;
}

.partner-label {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #3D3D5A;
  margin-bottom: 24px !important;
}

.partner-logo {
  height: 52px;
}

@media (max-width: 768px) {
  .business-metrics {
    padding: 100px 16px;
  }

  .metric-item {
    padding-right: 0 !important;
  }

  .metric-item:not(:last-child) {
    border-right: none !important;
  }

  .partners-section {
    padding: 100px 16px;
  }
}

/* STYLES FOR TESTIMONIALS */
.testimonials-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 96px !important;
  background-color: #EBEDF4;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.testimonials-section h3 {
  text-align: center;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 63px !important;
  letter-spacing: 1px !important;
}

.testimonials-card {
  border-radius: 16px !important;
  padding: 44px !important;
  box-shadow:
    0px 3px 6px 0px #00000008,
    0px 12px 12px 0px #00000008,
    0px 26px 16px 0px #00000005,
    0px 46px 19px 0px #00000000,
    0px 72px 20px 0px #00000000 !important;
}

.testimonials-card-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 0 !important;
  gap: 32px;
}

.testimonials-card-header svg {
  height: 24px;
  width: 24px;
}

.testimonials-card .MuiCardContent-root {
  padding-left: 0;
  padding-right: 0;
}

.testimonials-card-content {
  min-height: 240px;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #6B737E !important;

}

.testimonials-card-actions {
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.testimonials-card-actions img {
  height: 52px;
  width: 52px;
}

.testimonials-section a {
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  background-color: #6257FF !important;
  border-radius: 16px !important;
  padding: 12px 20px 12px 20px !important;
  width: fit-content;
  margin-top: 32px !important;
}

@media (max-width: 899px) {
  .testimonials-section {
    padding: 60px 12px !important;
  }
}


/* STYLES FOR BOOST CLIENT CHATS SECTION */
.boostClientChats-section {
  margin-left: 34px;
  margin-right: 34px;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: rgba(35, 35, 38, 1);
  border-radius: 32px;
  min-height: 392px;
}

.boostClientChats-left-content {
  padding-top: 73px !important;
  padding-left: 62px !important;
  padding-bottom: 73px !important;
}

.boostClientChats-title {
  font-size: 72px !important;
  font-weight: 500 !important;
  line-height: 72px !important;
  color: white !important;
}

.boostClientChats-subtitle {
  font-size: 20px !important;
  font-weight: 200 !important;
  line-height: 30px !important;
  color: white !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.boostClientChats-try-for-free-button {
  height: 42px;
  background-color: rgba(141, 43, 255, 1) !important;
}

.boostClientChats-right-content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.boostClientChats-logomark-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: -50px !important;
}

@media (max-width: 899px) {
  .boostClientChats-section {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .boostClientChats-left-content {
    display: flex !important;
    flex-direction: column !important;
    padding: 34px !important;
    padding-left: 50px !important;
  }

  .boostClientChats-title {
    font-size: 44px !important;
    font-weight: 500 !important;
    line-height: 49.28px !important;
    text-align: center;
  }

  .boostClientChats-subtitle {
    text-align: center;
  }
}