/* STYLES FOR PRICING */
.plans-container {
    padding: 100px 30px 30px 30px;
    background-color: #F4F5F6;
  }
  
  .plan-card-container {
    display: flex;
    justify-content: center;
  }
  
  .plan-card,
  .plan-card-container,
  .header-sticky {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .plan-card.hidden,
  .plan-card-container.hidden,
  .header-sticky.hidden {
    transform: translateY(-510px);
  }
  
  .plan-card.visible,
  .plan-card-container.visible,
  .header-sticky.visible {
    transform: translateY(0);
  }
  
  .plan-card-container {
    display: flex;
  }
  
  .header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: none;
    z-index: 1000;
    display: flex;
  }
  
  .header-container-sticky {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
  
  .card-sticky {
    width: 25%;
    margin: 24px 24px 0 24px;
  }
  
  .price-header-container {
    transform: translateY(60px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .price-header-container.visible {
    transform: translateY(0);
  }
  
  .plan-price-header {
    font-size: 36px !important;
    display: flex !important;
    width: 100%;
    font-weight: 600 !important;
    color: black !important;
    padding-top: 15px;
    display: flex !important;
    align-items: baseline;
  }
  
  .comparison-table {
    margin-top: 20px !important;
    box-shadow: none !important;
    border-radius: 16px !important;
  }
  
  .plan-card,
  .table-cell {
    width: 25%;
  }
  
  .table-cell {
    background-color: rgba(243, 246, 251, 1);
  }
  
  .add-on {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: none !important;
  }
  
  .title-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px;
  }
  
  .header-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 30px 24px 0 0;
  }
  
  .header-title {
    font-size: 28px !important;
    font-weight: 600 !important;
    line-height: 42px !important;
  }
  
  .switch-container {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 18px;
    border: 1px solid lightgray;
    border-radius: 20px;
    width: 193px;
  }
  
  .switch-container-label {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: rgba(0, 0, 0, 0.6) !important;
    margin: 0 auto;
  }
  
  .card-plan-0 {
    gap: 16px !important;
    border-radius: 8px !important;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04) !important;
    height: fit-content;
    min-height: 378px;
  }
  
  .card-plan-1 {
    gap: 16px !important;
    border-radius: 16px !important;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04) !important;
    height: fit-content;
  }
  
  .card-plan-2 {
    gap: 16px !important;
    border-radius: 16px !important;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04) !important;
    height: fit-content;
  }
  
  .card-plan-3 {
    gap: 16px !important;
    border-radius: 16px !important;
    width: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04) !important;
    height: fit-content;
  }
  
  .plan-header-gradient-0 {
    height: 8px;
    background-color: rgba(141, 43, 255, 0.24);
  }
  
  .plan-header-gradient-1 {
    height: 8px;
    background-color: rgba(88, 193, 214, 0.24);
  }
  
  .plan-header-gradient-2 {
    height: 8px;
    background-color: rgba(104, 163, 252, 0.24);
  }
  
  .plan-header-gradient-3 {
    height: 8px;
    background-color: rgba(142, 133, 255, 0.24);
  }
  
  .plan-card {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    width: 100% !important;
  }
  
  .plan-name {
    font-size: 43px !important;
    display: flex !important;
    font-weight: 600 !important;
    width: 100%;
    padding-bottom: 12px;
    line-height: 42px !important;
  }
  
  .plan-price-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    margin-top: 15px;
  }
  
  .plan-price-header-button-container {
    display: flex;
    align-items: center;
    height: 63px;
    margin-top: 15px;
  }
  
  .plan-price-header-button-container a,
  .plan-price-button-container a {
    color: white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    background-color: #6257FF !important;
    padding: 8px 16px 8px 16px !important;
    border-radius: 50px 50px 50px 50px !important;
    width: fit-content;
  }
  
  .plan-name-0 {
    color: black !important;
  }
  
  .plan-name-1 {
    color: rgba(88, 193, 214, 1) !important;
  }
  
  .plan-name-2 {
    color: rgba(104, 163, 252, 1) !important;
  }
  
  .plan-name-3 {
    color: rgba(142, 133, 255, 1) !important;
  }
  
  .plan-header {
    padding: 0 !important;
  }
  
  .plan-subtitle-wrapper {
    padding: 0 16px 16px 16px;
  }
  
  .card-trial-button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
  min-height: 54px !important;
    min-width: 220px !important;
  }
  
  .card-demo-button {
    border-color: #6257FF !important;
    background-color: white !important;
    border-radius: 16px !important;
    text-transform: none !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    color: #6257FF !important;
    min-height: 54px !important;
  }
  
  .plan-switch {
    background: white;
    height: 100%;
    display: flex;
    align-items: center;
    width: 130px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  .plan-note {
    width: 80%;
    background: #6257FF;
    color: white;
    border-radius: 16px;
    border-top-right-radius: 0;
    padding: 16px;
  }
  
  .plan-switch .MuiSwitch-root {
    margin-left: 16px;
  }
  
  .plan-switch-label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    background: #6257FF;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .plan-switch-label .switch-container-label {
    text-align: center;
    width: 100%;
    color: white !important;
  }
  
  .plan-price {
    font-size: 36px !important;
    display: flex !important;
    width: 100%;
    font-weight: 600 !important;
    color: black !important;
    padding-top: 15px;
    display: flex !important;
    align-items: baseline !important;
  }
  
  .plan-month {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #6B7280;
    margin-left: 6px;
  }
  
  .promotion-container-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background-color: rgba(22, 14, 32, 1) !important;
    box-shadow: none !important;
    border-radius: 24px !important;
    padding: 60px !important;
    margin-top: 30px;
  }
  
  .promotion-container-card h3 {
    font-size: 48px !important;
    font-weight: 700 !important;
    line-height: 63px !important;
    letter-spacing: 1px !important;
    color: white !important;
    text-align: center;
  }
  
  .promotion-container-card h6 {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    color: white !important;
    text-align: center;
  }
  
  .promotion-container-card span {
    color: #6581FF !important;
  }
  
  @media (max-width: 768px) {
    .plans-container {
      padding: 100px 12px 30px 12px;
    }
  
    .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .title-pricing {
      font-size: 32px !important;
      font-weight: 700 !important;
      line-height: 42px !important;
      text-align: center;
    }
  
    .subtitle-pricing {
      font-size: 18px !important;
      padding-top: 10px;
      text-align: center !important;
    }
  
    .promotion-container-card {
      padding: 28px 24px 28px 24px !important;
    }
  
    .promotion-container-card h3 {
      font-size: 36px !important;
      font-weight: 700 !important;
      line-height: 43px !important;
    }
  
    .card-buttons-stack {
      width: 100%;
      gap: 16px;
    }
  
    .card-demo-button, .card-trial-button {
      margin: 0 18px !important;
    }
  }
  