/* STYLES FOR HERO SECTION */
.live-chat-hero-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    background-color: rgba(18, 9, 29, 1);
    background-image:
        linear-gradient(to bottom,
            rgba(18, 9, 29, 1) 0%,
            rgba(18, 9, 29, 0) 10%),
        url("../../assets/img/planet_2.png");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
}

.live-chat-hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    padding: 180px 96px 100px 96px !important;
    gap: 14px !important
}

.live-chat-hero-section h1 {
    font-size: 60px !important;
    font-weight: 700 !important;
    line-height: 120% !important;
    color: white !important;
}

.live-chat-hero-section h3 {
    color: rgba(255, 255, 255, 0.72) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 130% !important;
    max-width: 60%;
}

.live-chat-hero-section button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    width: fit-content;
}

.live-chat-hero-section h6 {
    color: rgba(255, 255, 255, 0.72) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 120% !important;
}

.live-chat-image {
    width: 100%;
    border-radius: 16px;
    margin-top: 30px;
}

@media (max-width: 768px) {
    .live-chat-hero-content {
        height: 100vh;
        padding: 16px !important;
    }

    .live-chat-hero-section h1 {
        font-size: 40px !important;
        line-height: 112% !important;
    }

    .live-chat-hero-section a {
        width: 100% !important;
    }

    .live-chat-hero-section button {
        width: 100% !important;
    }
}

/* STYLES FOR FEATURES SECTION */
.live-chat-features-section {
    background: linear-gradient(180deg, rgba(235, 237, 244, 1) 0%, #FFFFFF 100%);
}

.live-chat-features-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    padding: 100px 0;
}

.live-chat-features-grid-container {
    width: 70% !important;
}

.live-chat-features-left {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 20px !important;
}

.live-chat-features-left h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 46px !important;
    letter-spacing: 0 !important;
}

.live-chat-features-left h6 {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
}

.live-chat-features-image-container {
    display: flex;
    justify-content: center;
}

.live-chat-features-image {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
}

.live-chat-features-demo-button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px 12px 20px !important;
    width: fit-content;
    margin-top: 100px !important;
}

.live-chat-features-trial-button {
    border: 1px solid #161A2866 !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    color: #161A2866 !important;
    margin-top: 100px !important;
}

@media (max-width: 768px) {
    .live-chat-features-container {
        padding: 100px 16px;
    }
    
    .live-chat-features-grid-container {
        width: 100% !important;
    }

    .live-chat-features-stack-buttons {
        flex-direction: column !important;
        gap: 16px;
        width: 100%;
    }

    .live-chat-features-stack-buttons a {
        margin-left: 0 !important;
    }

    .live-chat-features-demo-button,
    .live-chat-features-trial-button {
        width: 100% !important;
    }

    .live-chat-features-trial-button {
        margin: 0 !important;
    }
}

/* STYLES FOR CHANNEL SECTION */
.channels-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    padding: 100px 96px;
}

.channels-section-grid-container {
    max-width: 1200px !important;
}

.channels-section-left {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 20px !important;
}

.channels-section-left h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 46px !important;
    letter-spacing: 0 !important;
}

.channels-section-left h6 {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
}

.channels-section-image-container {
    display: flex;
    justify-content: center;
}

.channels-section-image {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
}

@media (max-width: 768px) {
    .channels-section {
        padding: 100px 16px;
    }
}

/* STYLES FOR GUIDE SECTION */
.live-chat-guide-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    background-color: rgba(18, 9, 29, 1);
    background-image:
        linear-gradient(to bottom,
            rgba(18, 9, 29, 1) 0%,
            rgba(18, 9, 29, 0) 20%),
        url("../../assets/img/planet.png");
    background-size: cover;
    background-position: center 100px;
    background-repeat: no-repeat;
    padding: 100px 96px;
}

.live-chat-guide-container h3 {
    color: white !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 63px !important;
    letter-spacing: 1px !important;
}

.live-chat-guide-video {
    width: 100%;
    max-width: 912px;
    border-radius: 16px;
    border: 10px solid rgba(255, 255, 255, 0.5);
    margin-top: 30px;
    margin-bottom: 100px;
}

.live-chat-business-outcomes {
    display: flex;
    flex-direction: column;
    padding: 0 96px 97px 96px;
    background: rgba(9, 8, 13, 1);
    background-image:
        linear-gradient(to bottom,
            rgba(9, 8, 13, 1) 0%,
            rgba(18, 9, 29, 0) 50%),
        url("../../assets/img/planet.png");
    background-size: cover;
    background-position: center 130px;
    background-repeat: no-repeat;
    text-align: center;
    gap: 44px !important;
}

.live-chat-business-outcomes h3 {
    color: white !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 63px !important;
    letter-spacing: 1px !important;
}

.metrics-grid {
    margin-bottom: 60px;
}

.metric-item {
    text-align: center;
    position: relative;
    padding-right: 20px;
}

.metric-item:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.metric-value {
    font-size: 84px !important;
    font-weight: 700 !important;
    color: white;
}

.metric-label {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: white;
}