/* STYLES FOR HERO SECTION */
.workflows-hero-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    background-color: rgba(18, 9, 29, 1);
    background-image:
        linear-gradient(to bottom,
            rgba(18, 9, 29, 1) 0%,
            rgba(18, 9, 29, 0) 10%),
        url("../../assets/img/planet_2.png");
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
}

.workflows-hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center !important;
    padding: 180px 96px 100px 96px !important;
    gap: 14px !important
}

.workflows-hero-section h1 {
    font-size: 60px !important;
    font-weight: 700 !important;
    line-height: 120% !important;
    color: white !important;
}

.workflows-hero-section h3 {
    color: rgba(255, 255, 255, 0.72) !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 130% !important;
    max-width: 60%;
}

.workflows-hero-section button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    margin-top: 0 !important;
    width: fit-content;
}

.workflows-hero-section h6 {
    color: rgba(255, 255, 255, 0.72) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 120% !important;
}

.workflows-image {
    width: 100%;
    border-radius: 16px;
    margin-top: 30px;
}

@media (max-width: 768px) {
    .workflows-hero-content {
        height: 100vh;
        padding: 16px !important;
    }

    .workflows-hero-content h1 {
        font-size: 40px !important;
        line-height: 112% !important;
    }

    .workflows-hero-content a {
        width: 100% !important;
    }

    .workflows-hero-content button {
        width: 100% !important;
        margin-top: 0 !important;
    }
}

/* STYLES FOR FEATURES SECTION */
.workflows-features-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: linear-gradient(180deg, #EAECF4 0%, #FFFFFF 67.81%);
    padding: 100px 96px;
}

.workflows-features-section-title {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 46px !important;
    letter-spacing: 0 !important;
    text-align: center;
    max-width: 1200px !important;
}

.workflows-features-section-subtitle {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    text-align: center;
    width: 100%;
}

.workflows-features-container {
    display: flex;
    justify-content: center;
    align-items: center !important;
    padding: 100px 0;
}

.workflows-features-grid-container {
    width: 70% !important;
}

.workflows-features-left {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 20px !important;
}

.workflows-features-left h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    letter-spacing: 1px !important;
}

.workflows-features-left h6 {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
}

.workflows-features-image-container {
    display: flex;
    justify-content: center;
}

.workflows-features-image {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
}

.workflows-demo-button {
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: #6257FF !important;
    border-radius: 16px !important;
    padding: 12px 20px 12px 20px !important;
    width: fit-content;
}

.workflows-trial-button {
    border: 1px solid #161A2866 !important;
    border-radius: 16px !important;
    padding: 12px 20px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    color: #161A2866 !important;
}

@media (max-width: 768px) {
    .workflows-features-section {
        padding: 100px 16px;
    }

    .workflows-features-grid-container {
        width: 100% !important;
    }    

    .workflows-features-section-stack-buttons {
        flex-direction: column !important;
        gap: 16px;
        width: 100%;
    }

    .workflows-features-section-stack-buttons a {
        margin-left: 0 !important;
    }

    .workflows-demo-button,
    .workflows-trial-button {
        width: 100% !important;
    }
}

/* STYLES FOR WORKFLOW TIMELINE SECTION */
.workflow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 100px 0;
    position: relative;
    background: linear-gradient(180deg, #FFFFFF 0%, #EBEDF4 100%);
}

.workflow-container h3 {
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 46px !important;
    letter-spacing: 0 !important;
}

.workflow-container h6 {
    color: #6B737E;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
}

.timeline-grid {
    position: relative;
    margin-top: 0 !important;
    width: 100%;
    max-width: 950px;
    margin-left: 0 !important;
}

.timeline-line {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #e0e0e0;
    transform: translateX(-50%);
}

.timeline-step {
    display: flex;
    align-items: center;
    padding-left: 0 !important;
}

.timeline-dot-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
}

.timeline-dot {
    width: 18px;
    height: 18px;
    background-color: #e0e0e0;
    border-radius: 50%;
    position: relative;
}

.timeline-inner-dot {
    width: 10px;
    height: 10px;
    background: rgba(136, 148, 188, 1);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card-container {
    display: inline-block;
    gap: 16px !important;
    background: white;
    border-radius: 16px !important;
    padding: 28px;
    text-align: left;
    max-width: 390px;
    box-shadow: 0px 3px 6px 0px #00000008,
        0px 12px 12px 0px #00000008,
        0px 26px 16px 0px #00000005,
        0px 46px 19px 0px #00000000,
        0px 72px 20px 0px #00000000 !important;
}

.card-container h5 {
    font-size: 24px !important;
    font-weight: 700 !important;
    color: black;
    margin-bottom: 16px;
}

.card-container p {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #6B737E;
}

.left-align {
    text-align: right;
}

.right-align {
    text-align: left;
}

@media (max-width: 768px) {
    .workflow-container {
        padding: 100px 0 100px 0;
    }

    .card-container {
        width: 300px !important;
    }

    .workflow-container h3 {
        padding-left: 16px;
        padding-right: 16px;
    }
    
    .workflow-container h6 {
        padding-left: 16px;
    }
}

/* STYLES FOR SMOOTH CONVERSATIONS SECTION */
.smooth-conversations-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    background: rgba(15, 15, 26, 1);
    background-image:
        linear-gradient(to bottom,
            rgba(18, 9, 29, 1) 0%,
            rgba(18, 9, 29, 0) 20%),
        url("../../assets/img/curved-line.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    gap: 30px;
    padding: 100px 96px;
}

.smooth-conversations-container::before,
.smooth-conversations-container::after {
    content: "";
    position: absolute;
    top: 0;
    width: 15%;
    height: 100%;
    z-index: 1;
}

.smooth-conversations-container::before {
    left: 0;
    background: linear-gradient(to right, rgba(15, 15, 26, 1) 0%, rgba(15, 15, 26, 0) 100%);
}

.smooth-conversations-container::after {
    right: 0;
    background: linear-gradient(to left, rgba(15, 15, 26, 1) 0%, rgba(15, 15, 26, 0) 100%);
}

.smooth-conversations-container h3 {
    font-size: 36px !important;
    font-weight: 400 !important;
    line-height: 50px !important;
    color: white !important;
    z-index: 1000;
}

.smooth-conversations-container h6 {
    color: rgba(255, 255, 255, 0.72);
    font-size: 20px !important;
    font-weight: 400 !important;
    z-index: 1000;
}

.smooth-conversations-container button {
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 16px !important;
    padding: 12px 20px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
    color: white !important;
}

@media (max-width: 768px) {
    .smooth-conversations-container {
        padding: 100px 16px;
        height: auto;
        background-size: contain;
        background-position: bottom;
        background-color: black;
    }
}