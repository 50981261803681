.cta-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px !important;
  text-align: center;
  padding: 120px 149px;
  background: rgba(18, 9, 29, 1); 
  overflow: hidden;
  backdrop-filter: blur(300px);
  z-index: 1;
}

.cta-container .circle1, .cta-container .circle2 {
  position: absolute;
  border-radius: 50%;
  background: rgba(141, 42, 255, 0.7);
  filter: blur(50px); 
}

.cta-container .circle1 {
  width: 500px;
  height: 500px;
  top: -150px;
  right: 350px;
  background: radial-gradient(circle, rgba(98, 87, 255, 0.4) 0%, rgba(98, 87, 255, 0.2) 60%);
  filter: blur(50px);
}

.cta-container .circle2 {
  width: 800px;
  height: 800px;
  bottom: -500px;
  left: 20%;
  background: radial-gradient(circle, rgba(141, 42, 255, 0.7) 0%, rgba(141, 42, 255, 0.2) 80%); 
  filter: blur(50px);
}

.cta-container h3 {
  font-weight: 400 !important;
  font-size: 36px !important;
  line-height: 140% !important;
  letter-spacing: 0px !important;
  color: white !important;
  z-index: 1000;
}

.cta-container h6 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 130% !important;
  letter-spacing: 0 !important;
  max-width: 850px;
}

.cta-container button {
  border: 1px solid white !important;
  border-radius: 16px !important;
  padding: 12px 20px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  color: white !important;
}

@media (max-width: 768px) {
  .cta-container {
      padding: 100px 16px;
  }

  .cta-container button {
      width: 100%;
  }
}
